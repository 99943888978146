import Link from "next/link";
import styled from "styled-components";

interface Props {
  locale: string;
}

export default function Page404({ locale }: Props) {
  return (
    <>
      <Wrapper>
        <section className="page_404">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 ">
                <div className="col-sm-10 col-sm-offset-1  text-center">
                  <div className="four_zero_four_bg">
                    <h1 className="text-center ">404</h1>
                  </div>
                  <div className="contant_box_404">
                    <h3 className="h2">Look like you're lost</h3>
                    <p>the page you are looking for not avaible!</p>
                    <Flex>
                      <Link href={`/${locale}`} className="link_404">
                        Go to Home
                      </Link>
                    </Flex>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  width: 1289px;
  margin: 0 auto;
  padding-bottom: 7rem;

  @media screen and (max-width: 1199px) {
    width: 100%;
    padding: 0 16px;
  }

  .page_404 {
    padding: 40px 0;
    background: #fff;
  }

  .page_404 img {
    width: 100%;
  }

  .four_zero_four_bg {
    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
    background-repeat: no-repeat;
  }

  .four_zero_four_bg h1 {
    font-size: 80px;
    text-align: center;
  }

  .contant_box_404 h3 {
    font-size: 25px;
    text-align: center;
  }

  .contant_box_404 p {
    text-align: center;
    font-size: 20px;
  }

  .link_404 {
    font-size: 25px;
    color: #fff !important;
    padding: 10px 20px;
    background: #222;
    margin: 20px 0;
    display: inline-flex;
  }
  .contant_box_404 {
    margin-top: -50px;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
`;
